// Images file for 'qualifiedsolarsurvey'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Average from './assets/average.png';
import Excellent from './assets/excellent.png';
import Fair from './assets/fair.png';
import Good from './assets/good.png';
import Poor from './assets/poor.png';
import Unselected from '../../../../src/images/unselected-circle-white.png';
import Selected from '../../../../src/images/selected-circle-white.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import SliderArrows from '../../../../src/images/slider-arrows.png';

export default {
  Favicon,
  Average,
  Excellent,
  Fair,
  Good,
  Poor,
  Unselected,
  Selected,
  Dropdown,
  SliderArrows,
};
